import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Text, Separator } from "@radix-ui/themes";
import { TargetIcon, TableIcon, PersonIcon } from "@radix-ui/react-icons";
import "./styles.css";
import SidebarItem from "../SidebarItem";
import GymSelectionField from "../GymSelectionField";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activePath, setActivePath] = useState(location.pathname);

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  const handleTabChange = (value) => {
    navigate(`/dashboard/${value}`);
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar">
        <Text
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "primary",
          }}
        >
          Olympia Fit
        </Text>

        <div style={{ marginTop: "3rem", marginBottom: "0", width: "100%" }}>
          <GymSelectionField />
        </div>

        <Separator
          orientation="horizontal"
          style={{
            width: "100%",
            margin: "1rem",
            marginTop: "0.5rem",
          }}
        />

        <SidebarItem
          icon={TargetIcon}
          label="Dashboard"
          active={activePath === "/dashboard/"}
          onClick={() => handleTabChange("")}
        />
        <SidebarItem
          icon={PersonIcon}
          label="Membros"
          active={activePath === "/dashboard/members"}
          onClick={() => handleTabChange("members")}
        />
        <SidebarItem
          icon={TableIcon}
          label="CRM"
          active={activePath === "/dashboard/crm"}
          onClick={() => handleTabChange("crm")}
        />
        <SidebarItem
          icon={PersonIcon}
          label="Funcionários"
          active={activePath === "/dashboard/staffing"}
          onClick={() => handleTabChange("staffing")}
        />
      </div>
      <Separator
        orientation="vertical"
        style={{
          height: "100vh",
        }}
      />
    </div>
  );
};

export default Sidebar;
