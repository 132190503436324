import React, { useState, useEffect } from "react";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Popover, TextField, Text } from "@radix-ui/themes";
import "./styles.css";

const MemberSearch = ({ onSearch }) => {
  const [width, setWidth] = useState(300);
  const handleInputChange = (event) => {
    onSearch(event.target.value);
  };

  const handleFocus = () => {
    setWidth(width + 40); // Increase width by 20px
  };

  const handleBlur = () => {
    setWidth(width - 40); // Reset width
  };

  return (
    <TextField.Root
      placeholder="Busque por membros..."
      onChange={handleInputChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      className="animated-text-field"
      style={{ width: `${width}px` }}
    >
      <TextField.Slot>
        <MagnifyingGlassIcon height="16" width="16" />
      </TextField.Slot>
    </TextField.Root>
  );
};

export default MemberSearch;
