import axios from "axios";
import Cookies from "js-cookie";
import { setupCache } from "axios-cache-interceptor";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});

const defaultClient = setupCache(instance, {
  maxAge: 15 * 60 * 1000,
});

defaultClient.interceptors.request.use((config) => {
  const authToken = Cookies.get("authToken");
  if (authToken) {
    config.headers
      ? (config.headers.Authorization = `Bearer ${authToken}`)
      : (config.headers = { Authorization: `Bearer ${authToken}` });
  }
  return config;
});

defaultClient.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("Interceptor error: ", error);
    if (error.response && error.response.status === 401) {
      // remove all cookies
      Cookies.remove("authToken");
      // redirect to login or logout the user
      window.location.href = "/login"; // replace with your login route
    }
    return Promise.reject(error);
  }
);

export default defaultClient;
