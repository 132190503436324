import React from "react";
import { StarIcon } from "@radix-ui/react-icons";
import { Button, Heading, Text } from "@radix-ui/themes";
import illustration from "../../../../assets/landpage/presentation_section_illustration.png";
import "./styles.css";

const PresentationSection = () => {
  return (
    <div className="section-container">
      <div className="section-column text">
        <Heading size="9" weight={"medium"}>
          Revolucione o generenciamento de academias com AI
        </Heading>
        <Text>
          Descubra o futuro da gestao de academias com as solugoes GymAl,
          automatizando operacoes e aumentando a eficiencia por meio de
          tecnologia de IA de ponta.
        </Text>
        <Button variant="surface">Conheca nossos produtos</Button>
        <div className="section-row trust">
          <div className="trustpilot-container">
            <StarIcon color="blue" />
            <Text>Trustpilot</Text>
          </div>
          <div className="stars-list-container">
            <StarIcon color="yellow" />
            <StarIcon color="yellow" />
            <StarIcon color="yellow" />
            <StarIcon color="yellow" />
            <StarIcon color="yellow" />
          </div>
          <div className="efficiency-container">
            <Heading>50%</Heading>
            <Text>de eficiencia</Text>
          </div>
        </div>
      </div>
      <div className="section-column image">
        <img
          height={600}
          src={illustration}
          className="illustration-image"
          alt="Presentation Section Illustration"
        />
      </div>
    </div>
  );
};

export default PresentationSection;
