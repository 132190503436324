import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import UserService from "../../../../api/authService/userService";
import {
  Badge,
  Button,
  Card,
  Heading,
  Separator,
  Skeleton,
  Table,
  Text,
} from "@radix-ui/themes";
import "./styles.css";
import StaffFormModal from "../StaffFormModal";

const StaffTable = () => {
  const [pageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(null);

  const {
    data,
    isFetching: isLoading,
    error,
  } = useQuery({
    queryKey: ["staff-table", { page, pageSize }],
    queryFn: async () => {
      const userService = new UserService();
      const response = await userService.getStaffs({ page, pageSize });
      setNext(response.data.next);
      return response.data.results;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  return (
    <Card variant="surface">
      <Heading>Funcionários</Heading>
      <Separator
        orientation="horizontal"
        size="1"
        color="gray"
        style={{ width: "100%", marginTop: "1rem", marginBottom: "0.5rem" }}
      />
      <Table.Root>
        <Table.Header>
          <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Email</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Cargo</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Desde de</Table.ColumnHeaderCell>
        </Table.Header>
        <Table.Body>
          {error && (
            <div className="error-container">
              <Text>Error ao carregar os funcionarios...</Text>
            </div>
          )}
          {data?.map((staff) => (
            <Table.Row key={staff.id}>
              <Table.RowHeaderCell>
                {staff.user.first_name} {staff.user.last_name}
              </Table.RowHeaderCell>
              <Table.Cell>{staff.user.email}</Table.Cell>
              <Table.Cell>
                <Badge size={"2"}>{staff.staff_type}</Badge>
              </Table.Cell>
              <Table.Cell>
                {new Date(staff.created_at).toLocaleDateString("pt-BR")}
              </Table.Cell>
            </Table.Row>
          ))}
          {isLoading && (
            <Table.Row>
              <Table.Cell>
                <Skeleton>
                  <Text>User Name Structure</Text>
                </Skeleton>
              </Table.Cell>
              <Table.Cell>
                <Skeleton>
                  <Text>myuser_simple@gmail.com</Text>
                </Skeleton>
              </Table.Cell>
              <Table.Cell>
                <Skeleton>
                  <Badge size={"2"}>EXAMPLE</Badge>
                </Skeleton>
              </Table.Cell>
              <Table.Cell>
                <Skeleton>
                  <Text>XX/XX/XXXX</Text>
                </Skeleton>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table.Root>
      <div className="pagination-container">
        <StaffFormModal
          callback={() => setPage(1)}
          queryKey={["staff-table"]}
        />
        <Button
          variant="surface"
          disabled={page <= 1}
          onClick={() => setPage((prev) => prev - 1)}
        >
          Anterior
        </Button>
        <Button
          disabled={!next}
          variant="surface"
          onClick={() => setPage((prev) => prev + 1)}
        >
          Próximo
        </Button>
      </div>
    </Card>
  );
};

export default StaffTable;
