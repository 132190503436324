import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import UserService from "../../../../api/authService/userService";
import { toast } from "react-toastify";
import NewMemberForm from "../NewMemberForm";
import { AlertDialog, Button, Flex } from "@radix-ui/themes";

const NewMemberModal = ({ member, callback, queryKey, triggerComponent }) => {
  const userService = new UserService();
  const queryClient = useQueryClient();
  const alreadyExists = member ? true : false;
  const [currentMember, setCurrentMember] = useState(
    member || {
      member_type: "DEMO",
    }
  );
  const [open, setOpen] = useState(false);

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setCurrentMember({
      ...currentMember,
      user: { ...currentMember.user, [name]: value },
    });

    if (name === "email") {
      setCurrentMember({
        ...currentMember,
        user: { ...currentMember.user, [name]: value, username: value },
      });
    }
  };

  const handleSubmit = useMutation({
    mutationFn: async (event) => {
      event.preventDefault();
      if (alreadyExists) {
        return userService.updateMember(currentMember);
      } else {
        return userService.createMember(currentMember);
      }
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success("Membro cadastrado com sucesso");
      setCurrentMember({});
      callback?.();
      setOpen(false);
    },
    onError: (error) => {
      toast.error("Erro ao cadastrar membro");
      console.error(error);
    },
  });

  return (
    <AlertDialog.Root key={currentMember.id} open={open} onOpenChange={setOpen}>
      <AlertDialog.Trigger asChild>
        {React.cloneElement(triggerComponent, {
          onClick: () => setOpen(true),
        })}
      </AlertDialog.Trigger>
      <AlertDialog.Content
        maxWidth="450px"
        aria-description="Cadastrar novo membro"
      >
        <AlertDialog.Title>
          {alreadyExists ? "Atualizar membro" : "Cadastrar novo membro"}
        </AlertDialog.Title>
        <AlertDialog.Description>
          Preencha os campos abaixo para cadastrar um novo membro.
        </AlertDialog.Description>
        <div style={{ height: "1rem" }} />
        <NewMemberForm
          {...{
            handleUserChange,
            currentMember,
            setCurrentMember,
          }}
        />
        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Cancel>
            <Button variant="soft" color="gray">
              Cancelar
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button
              variant="solid"
              onClick={handleSubmit.mutate}
              loading={handleSubmit.isLoading}
            >
              {alreadyExists ? "Atualizar" : "Cadastrar"}
            </Button>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};

export default NewMemberModal;
