import React from "react";
import "./styles.css";
import { FaChartLine } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { IoChatbubbles } from "react-icons/io5";
import { MdAccessTimeFilled } from "react-icons/md";

import { Avatar, Heading } from "@radix-ui/themes";

const ExperienceSection = () => {
  return (
    <div className="experience-container">
      <Heading size="8">Transforme sua experiencia na academia</Heading>
      <div className="section-items-container">
        <div className="section-item">
          <div className="experience-icon-item">
            <FaChartLine />
          </div>
          <div className="section-item-column">
            <Heading size="4">Aumente sua eficiencia hoje</Heading>
            <p>
              Aumente a eficiencia com nossas ferramentas de automacao de ponta
            </p>
          </div>
        </div>
        <div className="section-item">
          <div className="experience-icon-item">
            <MdAccessTimeFilled />
          </div>
          <div className="section-item-column">
            <Heading size="4">Economize um tempo valioso</Heading>
            <p>
              Passe mais tempo focando em seus clientes e menos tempo em tarefas
              repetitivas e sem valor agregado.
            </p>
          </div>
        </div>
        <div className="section-item">
          <div className="experience-icon-item">
            <IoChatbubbles />
          </div>
          <div className="section-item-column">
            <Heading size="4">Obtenha uma comunicacao perfeita</Heading>
            <p>
              Garanta uma interacao suave com canais de comunicacao alimentados
              por IA.
            </p>
          </div>
        </div>
        <div className="section-item">
          <div className="experience-icon-item">
            <FaLock />
          </div>
          <div className="section-item-column">
            <Heading size="4">Manuseio seguro de dados</Heading>
            <p>
              Proteja dados confidenciais com seguranca robusta. Cuidamos da
              seguranca para voce focar no que importa.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceSection;
