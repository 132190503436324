import React from "react";
import "./styles.css";
import {
  Button,
  Card,
  Heading,
  Text,
  TextArea,
  TextField,
} from "@radix-ui/themes";

const GetInTouchSection = () => {
  return (
    <section className="get-in-touch-section">
      <div className="get-in-touch-column">
        <Heading>Entre em contato</Heading>
        <Text>Entre em contato conosco para conhecer nossa solucoes.</Text>
      </div>
      <div className="get-in-touch-card-column">
        <Card>
          <div className="get-in-touch-card-form">
            <TextField.Root placeholder="Nome" />
            <TextField.Root placeholder="Email" />
            <TextArea resize="vertical" placeholder="Mensagem" />
            <Button>Enviar</Button>
          </div>
        </Card>
      </div>
    </section>
  );
};

export default GetInTouchSection;
