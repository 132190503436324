import "./styles.css";
import React, { useState } from "react";
import { Badge, DataList, IconButton, Skeleton, Text } from "@radix-ui/themes";
import { FaEnvelope, FaEye, FaEyeSlash, FaWhatsapp } from "react-icons/fa";

const MemberAccessDetails = ({ member }) => {
  const [showCPF, setShowCPF] = useState(false);

  return (
    <DataList.Root>
      <DataList.Item>
        <DataList.Label>Nome</DataList.Label>
        <DataList.Value>{member.name}</DataList.Value>
      </DataList.Item>

      <DataList.Item>
        <DataList.Label>Email</DataList.Label>
        <DataList.Value>
          <div className="item-button-container">
            <Text>{member.email}</Text>
            <IconButton
              size={"1"}
              variant="ghost"
              onClick={() => window.open(`mailto:${member.email}`)}
            >
              <FaEnvelope />
            </IconButton>
          </div>
        </DataList.Value>
      </DataList.Item>

      <DataList.Item>
        <DataList.Label>Telefone</DataList.Label>
        <DataList.Value>
          <div className="item-button-container">
            <Text>{member.phone}</Text>
            <IconButton
              size={"1"}
              variant="ghost"
              onClick={() =>
                window.open(
                  `https://api.whatsapp.com/send?phone=55${member.phone}`
                )
              }
            >
              <FaWhatsapp />
            </IconButton>
          </div>
        </DataList.Value>
      </DataList.Item>

      <DataList.Item>
        <DataList.Label>Idade</DataList.Label>
        <DataList.Value>
          <div className="item-button-container">
            <Badge>
              {`${
                new Date().getFullYear() -
                new Date(member.birth_date).getFullYear()
              } Anos`}
            </Badge>
            <Text size={"1"}>
              {new Date(member.birth_date).toLocaleDateString("pt-BR")}
            </Text>
          </div>
        </DataList.Value>
      </DataList.Item>

      <DataList.Item>
        <DataList.Label>CPF</DataList.Label>
        <DataList.Value>
          <div className="item-button-container">
            {showCPF ? (
              <Text>{member.cpf}</Text>
            ) : (
              <Skeleton>
                <Text>{member.cpf}</Text>
              </Skeleton>
            )}
            <IconButton
              size={"1"}
              variant="ghost"
              onClick={() => setShowCPF(!showCPF)}
            >
              {showCPF ? <FaEyeSlash /> : <FaEye />}
            </IconButton>
          </div>
        </DataList.Value>
      </DataList.Item>

      <DataList.Item>
        <DataList.Label>Endereco</DataList.Label>
        <DataList.Value>
          {member.address}, {member.city}, {member.state} - {member.country}
        </DataList.Value>
      </DataList.Item>

      <DataList.Item>
        <DataList.Label>Criado em</DataList.Label>
        <DataList.Value>
          {new Date(member.created_at).toLocaleDateString("pt-BR")}
        </DataList.Value>
      </DataList.Item>
    </DataList.Root>
  );
};

export default MemberAccessDetails;
