import "./styles.css";
import React from "react";
import { Callout, Select, TextField } from "@radix-ui/themes";
import { InfoCircledIcon } from "@radix-ui/react-icons";

const NewMemberForm = ({
  handleUserChange,
  currentMember,
  setCurrentMember,
}) => {
  return (
    <div className="form-container">
      <TextField.Root
        placeholder="Nome"
        name="first_name"
        onChange={handleUserChange}
        value={currentMember.user?.first_name}
      />
      <TextField.Root
        name="last_name"
        placeholder="Sobrenome"
        onChange={handleUserChange}
        value={currentMember.user?.last_name}
      />

      <TextField.Root
        name="email"
        placeholder="Email"
        onChange={handleUserChange}
        value={currentMember.user?.email}
      />

      <Select.Root
        defaultValue="DEMO"
        onValueChange={(value) =>
          setCurrentMember({ ...currentMember, member_type: value })
        }
      >
        <Select.Trigger />
        <Select.Content>
          <Select.Group>
            <Select.Label>Planos tradicionais</Select.Label>
            <Select.Item value="PREMIUM">Premium</Select.Item>
            <Select.Item value="PRO">Profissional</Select.Item>
            <Select.Item value="BASIC">Básico</Select.Item>
            <Select.Item value="DEMO">Demonstração</Select.Item>
          </Select.Group>
          <Select.Separator />
          <Select.Group>
            <Select.Label>Especiais</Select.Label>
            {/* <Select.Item value="">Carrot</Select.Item> */}
            {/* <Select.Item value="potato">Potato</Select.Item> */}
          </Select.Group>
        </Select.Content>
      </Select.Root>

      <Callout.Root>
        <Callout.Icon>
          <InfoCircledIcon />
        </Callout.Icon>
        <Callout.Text>
          Após o cadastro, o usuário receberá um email com as instruções de
          acesso ao app Olympia Fit, e poderá alterar a senha no primeiro
          acesso.
        </Callout.Text>
      </Callout.Root>
    </div>
  );
};

export default NewMemberForm;
