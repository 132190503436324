import "./App.css";
import CRM from "./pages/crm";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
// import { ThemePanel } from "@radix-ui/themes";
import { ToastContainer } from "react-toastify";
import AuthRoute from "./components/shared/AuthRoute";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Staffing from "./pages/staffing";
import SetPasswordPage from "./pages/SetPasswordPage";
import LandPage from "./pages/land/land";
import MembersPage from "./pages/MembersPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandPage />,
  },
  {
    path: "dashboard",
    element: (
      <AuthRoute>
        <Dashboard />
      </AuthRoute>
    ),
  },
  {
    path: "dashboard/members",
    element: (
      <AuthRoute>
        <MembersPage />
      </AuthRoute>
    ),
  },
  {
    path: "dashboard/crm",
    element: (
      <AuthRoute>
        <CRM />
      </AuthRoute>
    ),
  },
  {
    path: "dashboard/staffing",
    element: (
      <AuthRoute>
        <Staffing />
      </AuthRoute>
    ),
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "set-password/:uidb64/:token",
    element: <SetPasswordPage />,
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
      {/* <ThemePanel /> */}
      <ToastContainer />
    </>
  );
}

export default App;
