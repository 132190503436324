import "./styles.css";
import React from "react";
import { Text } from "@radix-ui/themes";
import { FaTools, FaUser } from "react-icons/fa";

const ITEM_ICONS = {
  "fas fa-user": <FaUser />,
};

const SearchResultItemBase = ({ result, onClick }) => {
  return (
    <div className="search-result-item" onClick={onClick}>
      <div className="search-result-item-icon">
        {ITEM_ICONS[result.icon] || <FaTools />}
      </div>
      <div className="search-result-item-column">
        <Text weight={"bold"}>{result.command}</Text>
        <Text size="1">{result.subtitle}</Text>
      </div>
    </div>
  );
};

export default SearchResultItemBase;
