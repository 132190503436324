import React, { useEffect, useRef } from "react";
import "./styles.css";
import { Card, Heading, Text } from "@radix-ui/themes";

const MetricSection = () => {
  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <div className="metric-section">
      <Card ref={cardRef} className="metric-card" variant="surface">
        <div className="metric-card-container">
          <div className="metric-container">
            <Heading size="8">100+</Heading>
            <Text align={"center"}>
              Operacoes orientadas por IA que aumentam a efciencia da academia.
            </Text>
          </div>
          <div className="metric-container">
            <Heading size="8">500+</Heading>
            <Text align={"center"}>
              Processos simplificados para maior produtividade geral.
            </Text>
          </div>
          <div className="metric-container">
            <Heading size="8">99%</Heading>
            <Text align={"center"}>
              Os insights preditivos de IA impulsionam a tomada de decisoes.
            </Text>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default MetricSection;
