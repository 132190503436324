import React, { useState } from "react";
import { Button, AlertDialog, TextField, Flex } from "@radix-ui/themes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import UserService from "../../../../api/authService/userService";
import { toast } from "react-toastify";
import "./styles.css";

const GymFormModal = ({ gym, callback, queryKey }) => {
  const userService = new UserService();
  const queryClient = useQueryClient();
  const alreadyExists = gym ? true : false;
  const [currentGym, setCurrentGym] = useState(gym || {});

  const handleGymChange = (e) => {
    const { name, value } = e.target;
    setCurrentGym({ ...currentGym, [name]: value });
  };

  const handleSubmit = useMutation({
    mutationFn: async (event) => {
      event.preventDefault();
      if (alreadyExists) {
        return userService.updateGym(currentGym);
      } else {
        return userService.createGym(currentGym);
      }
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success("Academia cadastrada com sucesso");
      setCurrentGym({});
      callback?.();
    },
    onError: (error) => {
      toast.error("Erro ao cadastrar academia");
      console.error(error);
    },
  });

  return (
    <AlertDialog.Root>
      <AlertDialog.Trigger>
        <Button>
          {alreadyExists ? "Atualizar academia" : "Cadastrar nova academia"}
        </Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content maxWidth="450px">
        <AlertDialog.Title>
          {alreadyExists ? "Atualizar academia" : "Cadastrar nova academia"}
        </AlertDialog.Title>

        <div className="form-container">
          <TextField.Root
            name="name"
            placeholder="Nome"
            value={currentGym.name}
            onChange={handleGymChange}
          />
          <TextField.Root
            name="address"
            placeholder="Endereço"
            value={currentGym.address}
            onChange={handleGymChange}
          />

          <TextField.Root
            name="phone"
            placeholder="Telefone"
            value={currentGym.phone}
            onChange={handleGymChange}
          />

          <TextField.Root
            name="email"
            placeholder="Email"
            value={currentGym.email}
            onChange={handleGymChange}
          />
        </div>

        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Cancel>
            <Button variant="soft" color="gray">
              Cancelar
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button
              variant="solid"
              onClick={handleSubmit.mutate}
              loading={handleSubmit.isLoading}
            >
              {alreadyExists ? "Atualizar" : "Cadastrar"}
            </Button>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};

export default GymFormModal;
