import React from "react";
import "./styles.css";
import { Heading } from "@radix-ui/themes";
import ResourceCard from "../ResourceCard";

const resources = [
  {
    title: "Moderno",
    description: "Um sistema moderno e fácil de usar",
    content: [
      "Com design moderno e intuitivo",
      "Fácil de usar e configurar",
      "Cativante e responsivo",
      "Focado na experiência do usuário",
      "Feito para donos de academia",
      "Criado em parceria com profissionais da área",
    ],
  },
  {
    title: "Baseado em IA",
    description: "Um sistema baseado em inteligência artificial",
    content: [
      "Recomendações personalizadas",
      "Análise de dados em tempo real",
      "Previsões de vendas e faturamento",
      "Otimização de processos",
      "Melhoria contínua",
    ],
  },
  {
    title: "Personalizável",
    description: "Um sistema que se adapta ao seu negócio",
    content: [
      "Personalize o sistema de acordo com suas necessidades",
      "Crie relatórios personalizados",
      "Adicione funcionalidades extras",
      "Integre com outros sistemas",
      "Ajuste o sistema conforme seu crescimento",
    ],
  },
];

const ResourcesSection = () => {
  return (
    <div className="resources-container">
      <div className="resources-title">
        <Heading size="7">Muito mais que um simples ERP</Heading>
        <Heading size="4">Alavancando seu negócio com AI</Heading>
      </div>
      <div className="resources-content">
        <ResourceCard resource={resources[0]} />
        <ResourceCard resource={resources[1]} />
        <ResourceCard resource={resources[2]} />
      </div>
    </div>
  );
};

export default ResourcesSection;
