import GeneralSearch from "../../components/shared/GeneralSearch";
import Layout from "../layout";

const Dashboard = () => {
  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: "1rem",
        }}
      >
        <GeneralSearch />
        <h1>Dashboard</h1>
      </div>
    </Layout>
  );
};

export default Dashboard;
