import React, { useState } from "react";
import "./styles.css";
import {
  Button,
  AlertDialog,
  TextField,
  Flex,
  Callout,
  Select,
} from "@radix-ui/themes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import UserService from "../../../../api/authService/userService";
import { toast } from "react-toastify";
import { InfoCircledIcon } from "@radix-ui/react-icons";

const StaffFormModal = ({ staff, callback, queryKey }) => {
  const userService = new UserService();
  const queryClient = useQueryClient();
  const alreadyExists = staff ? true : false;
  const [currentStaff, setCurrentStaff] = useState(
    staff || { staff_type: "DEMO" }
  );

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setCurrentStaff({
      ...currentStaff,
      user: { ...currentStaff.user, [name]: value },
    });

    if (name === "email") {
      // Also set the username to the email value if it's not already set
      // This is a common pattern to ensure that the username is always set
      setCurrentStaff({
        ...currentStaff,
        user: { ...currentStaff.user, [name]: value, username: value },
      });
    }
  };

  const handleSubmit = useMutation({
    mutationFn: async (event) => {
      event.preventDefault();
      if (alreadyExists) {
        return userService.updateStaff(currentStaff);
      } else {
        return userService.createStaff(currentStaff);
      }
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success("Staff cadastrado com sucesso");
      setCurrentStaff({ staff_type: "DEMO" });
      callback?.();
    },
    onError: (error) => {
      toast.error("Erro ao cadastrar Staff");
      console.error(error);
    },
  });

  return (
    <AlertDialog.Root>
      <AlertDialog.Trigger>
        <Button>
          {alreadyExists
            ? "Atualizar funcionario"
            : "Cadastrar novo funcionario"}
        </Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content maxWidth="450px">
        <AlertDialog.Title>
          {alreadyExists
            ? "Atualizar funcionario"
            : "Cadastrar novo funcionario"}
        </AlertDialog.Title>

        <div className="form-container">
          <TextField.Root
            placeholder="Nome"
            name="first_name"
            onChange={handleUserChange}
            value={currentStaff.user?.first_name}
          />
          <TextField.Root
            name="last_name"
            placeholder="Sobrenome"
            onChange={handleUserChange}
            value={currentStaff.user?.last_name}
          />

          <TextField.Root
            name="email"
            placeholder="Email"
            onChange={handleUserChange}
            value={currentStaff.user?.email}
          />

          <Select.Root
            defaultValue="DEMO"
            onValueChange={(value) =>
              setCurrentStaff({ ...currentStaff, staff_type: value })
            }
          >
            <Select.Trigger />
            <Select.Content>
              <Select.Group>
                <Select.Label>Cargos tradicionais</Select.Label>
                <Select.Item value="DIRECTOR">Diretor</Select.Item>
                <Select.Item value="ADMIN">Administrador</Select.Item>
                <Select.Item value="MANAGER">Manager</Select.Item>
                <Select.Item value="COLAB">Colaborador</Select.Item>
                <Select.Item value="COACH">Treinador</Select.Item>
              </Select.Group>
              <Select.Separator />
              <Select.Group>
                <Select.Label>Especiais</Select.Label>
                <Select.Item value="DEMO">Demonstração</Select.Item>
              </Select.Group>
            </Select.Content>
          </Select.Root>

          <Callout.Root>
            <Callout.Icon>
              <InfoCircledIcon />
            </Callout.Icon>
            <Callout.Text>
              Após o cadastro, o usuário receberá um email com as instruções de
              acesso ao app da Olympia Fit, e poderá alterar a senha no primeiro
              acesso.
            </Callout.Text>
          </Callout.Root>
        </div>

        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Cancel>
            <Button variant="soft" color="gray">
              Cancelar
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button
              variant="solid"
              onClick={handleSubmit.mutate}
              loading={handleSubmit.isLoading}
            >
              {alreadyExists ? "Atualizar" : "Cadastrar"}
            </Button>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};

export default StaffFormModal;
