import React, { useState, useEffect, useCallback, useRef } from "react";
import "./styles.css";
import { Spinner, TextField } from "@radix-ui/themes";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { useQuery } from "@tanstack/react-query";
import debounce from "lodash.debounce";
import SearchResultItem from "./components/SearchResultItem";
import CommandSearchService from "../../../api/commandSearchService/commandSearchService";

const GeneralSearch = ({ triggerComponent }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const commandSearchService = new CommandSearchService();
  const searchContainerRef = useRef(null);

  const debouncedSearchQuery = useCallback(
    debounce((query) => {
      setSearchQuery(query);
    }, 300),
    []
  );

  const {
    data: searchResults,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["search-results", searchQuery],
    queryFn: async () => {
      if (searchQuery.trim() === "") return [];
      const response = await commandSearchService.searchCommand({
        query: searchQuery.trim(),
      });
      return response;
    },
    enabled: false,
  });

  useEffect(() => {
    if (searchQuery.trim() !== "") {
      refetch();
    }
  }, [searchQuery, refetch]);

  const handleInputChange = (event) => {
    debouncedSearchQuery(event.target.value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleClickOutside = (event) => {
    if (
      searchContainerRef.current &&
      !searchContainerRef.current.contains(event.target)
    ) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="general-search-container" ref={searchContainerRef}>
      <TextField.Root
        size="2"
        placeholder="Assistente AI..."
        onFocus={handleFocus}
        onChange={handleInputChange}
      >
        <TextField.Slot>
          <MagnifyingGlassIcon height="16" width="16" />
        </TextField.Slot>
      </TextField.Root>
      <div
        className={`search-results-container ${
          (searchResults || isFetching) && isFocused ? "show" : ""
        }`}
      >
        {isFetching ? (
          <div className="loading-indicator">
            <Spinner />
          </div>
        ) : searchResults?.length === 0 ? (
          <div className="empty-results-message">
            Nenhum resultado encontrado para "{searchQuery}" ...
          </div>
        ) : (
          searchResults?.map((result) => (
            <SearchResultItem key={result.id} result={result} />
          ))
        )}
      </div>
    </div>
  );
};

export default GeneralSearch;
