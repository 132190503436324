import Cookies from "js-cookie";
import defaultClient from "../client/default";

class UserService {
  constructor() {
    this.defaultClient = defaultClient;
    this.serviceUrl = "api/v1/user/";
  }

  async me() {
    const meContent = Cookies.get("me");
    if (meContent) return JSON.parse(meContent);
    try {
      const me = await defaultClient.get(this.serviceUrl + "me/staff/");
      Cookies.set("me", JSON.stringify(me.data));
      return me.data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  }

  async getGyms({ page = 1, pageSize = 10 }) {
    const currentUser = await this.me();
    try {
      return await defaultClient.get(this.serviceUrl + "gym/", {
        params: {
          page: page,
          page_size: pageSize,
          company__id: currentUser.company.id,
        },
      });
    } catch (error) {
      console.error("Error fetching gyms:", error);
      throw error;
    }
  }

  async createGym(data) {
    try {
      return await defaultClient.post(this.serviceUrl + "gym/", data);
    } catch (error) {
      console.error("Error creating gym:", error);
      throw error;
    }
  }

  async updateGymField(id, field, value) {
    try {
      return await defaultClient.patch(this.serviceUrl + `gym/${id}/`, {
        [field]: value,
      });
    } catch (error) {
      console.error("Error updating gym field:", error);
      throw error;
    }
  }

  async updateGym(id, data) {
    try {
      return await defaultClient.put(this.serviceUrl + `gym/${id}/`, data);
    } catch (error) {
      console.error("Error updating gym:", error);
      throw error;
    }
  }

  async getMembers({ page = 1, pageSize = 10, searchQuery = null }) {
    const currentUser = await this.me();
    try {
      return await defaultClient.get(this.serviceUrl + "member/", {
        params: {
          page: page,
          page_size: pageSize,
          search: searchQuery,
          company__id: currentUser.company.id,
        },
      });
    } catch (error) {
      console.error("Error fetching members:", error);
      throw error;
    }
  }

  async createMember(data) {
    try {
      return await defaultClient.post(this.serviceUrl + "member/", data);
    } catch (error) {
      console.error("Error creating member:", error);
      throw error;
    }
  }

  async getStaffs({ page = 1, pageSize = 10 }) {
    const currentUser = await this.me();
    try {
      return await defaultClient.get(this.serviceUrl + "staff/", {
        params: {
          page: page,
          page_size: pageSize,
          company__id: currentUser.company.id,
        },
      });
    } catch (error) {
      console.error("Error fetching staffs:", error);
      throw error;
    }
  }

  async createStaff(data) {
    try {
      return await defaultClient.post(this.serviceUrl + "staff/", data);
    } catch (error) {
      console.error("Error creating staff:", error);
      throw error;
    }
  }

  async updateStaffField(id, field, value) {
    try {
      return await defaultClient.patch(this.serviceUrl + `staff/${id}/`, {
        [field]: value,
      });
    } catch (error) {
      console.error("Error updating staff field:", error);
      throw error;
    }
  }

  async updateStaff(id, data) {
    try {
      return await defaultClient.put(this.serviceUrl + `staff/${id}/`, data);
    } catch (error) {
      console.error("Error updating staff:", error);
      throw error;
    }
  }
}
export default UserService;
