import React from "react";
import {
  Avatar,
  Badge,
  IconButton,
  Table,
  Text,
  Dialog,
} from "@radix-ui/themes";
import { FaEnvelope, FaPhone, FaWhatsapp } from "react-icons/fa";
import MemberDialogView from "../../../MemberDialogView";
import "./styles.css";

const TableRow = ({ member, index }) => {
  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Table.Row
          key={member.id}
          className="table-clickable-row"
          onClick={() => {
            console.log("Clicked on member", member);
          }}
        >
          <Table.RowHeaderCell>
            <div className="row-container">
              <Avatar src={member.avatar} fallback="A" />
              <div className="row-column">
                <Text>{member.name}</Text>
                <Text size={"1"}>{member.email}</Text>
              </div>
              <div className="tags-container"></div>
            </div>
          </Table.RowHeaderCell>
          <Table.Cell>
            <Badge size={"2"}>{member.member_type}</Badge>
          </Table.Cell>
          <Table.Cell>
            {new Date(member.created_at).toLocaleDateString("pt-BR")}
          </Table.Cell>
          <Table.Cell>
            <div className="contact-container">
              <IconButton variant="surface">
                <FaWhatsapp />
              </IconButton>
              <IconButton variant="surface">
                <FaPhone />
              </IconButton>
              <IconButton variant="surface">
                <FaEnvelope />
              </IconButton>
            </div>
          </Table.Cell>
        </Table.Row>
      </Dialog.Trigger>
      <Dialog.Content className="dialog-content">
        <MemberDialogView member={member} />
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default TableRow;
