import React, { useState, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import UserService from "../../../../api/authService/userService";
import {
  Button,
  Card,
  Heading,
  Separator,
  Table,
  Text,
} from "@radix-ui/themes";
import "./styles.css";
import MemberSearch from "../MemberSearch";
import debounce from "lodash.debounce";
import { PersonIcon } from "@radix-ui/react-icons";
import LoadingRow from "./components/LoadingRow";
import TableRow from "./components/TableRow";
import NewMemberModal from "../NewMemberModal";

const MembersTable = () => {
  const [pageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const debouncedSearchQuery = useCallback(
    debounce((query) => setSearchQuery(query), 300),
    []
  );

  const {
    data,
    isFetching: isLoading,
    error,
  } = useQuery({
    queryKey: ["members-table", { page, pageSize, searchQuery }],
    queryFn: async () => {
      const userService = new UserService();
      const response = await userService.getMembers({
        page,
        pageSize,
        searchQuery,
      });
      setNext(response.data.next);
      return response.data.results;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  return (
    <Card variant="surface">
      <div className="members-table-heading-row">
        <div className="members-table-title-row">
          <PersonIcon height="24" width="24" />
          <Heading>Meus membros</Heading>
        </div>
        <MemberSearch onSearch={debouncedSearchQuery} />
      </div>
      <Separator
        orientation="horizontal"
        size="1"
        color="gray"
        style={{ width: "100%", marginTop: "1rem", marginBottom: "0.5rem" }}
      />
      <Table.Root variant="classic">
        <Table.Header>
          <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Plano</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Desde de</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Contato</Table.ColumnHeaderCell>
        </Table.Header>
        <Table.Body>
          {error && (
            <>
              <LoadingRow />
              <div className="exception-container">
                <Card variant="surface" className="exception-card">
                  <div className="exception-card-content">
                    <Text>❌</Text>
                    <Text>Erro ao obter os dados, tente mais tarde.</Text>
                  </div>
                </Card>
              </div>
            </>
          )}
          {!isLoading && data?.length === 0 && (
            <>
              <LoadingRow />
              <div className="exception-container">
                <Card variant="surface" className="exception-card">
                  <div className="exception-card-content">
                    <Text>🔍</Text>
                    <Text>Nenhum resultado encontrado</Text>
                  </div>
                </Card>
              </div>
            </>
          )}
          {!isLoading &&
            data?.map((member) => <TableRow key={member.id} member={member} />)}
          {isLoading && <LoadingRow />}
        </Table.Body>
      </Table.Root>
      <div className="pagination-container">
        <NewMemberModal
          callback={() => setPage(1)}
          queryKey={["members-table"]}
          triggerComponent={
            <Button>
              <Text>Cadastrar novo membro</Text>
            </Button>
          }
        />
        <Button
          variant="surface"
          disabled={page <= 1}
          onClick={() => setPage((prev) => prev - 1)}
        >
          Anterior
        </Button>
        <Button
          disabled={!next}
          variant="surface"
          onClick={() => setPage((prev) => prev + 1)}
        >
          Próximo
        </Button>
      </div>
    </Card>
  );
};

export default MembersTable;
