import MembersTable from "../../components/global/Member/MemberTable";
import Layout from "../layout";
import "./styles.css";

const MembersPage = () => {
  return (
    <Layout>
      <div className="content-container">
        <MembersTable />
      </div>
    </Layout>
  );
};

export default MembersPage;
