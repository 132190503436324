import GymTable from "../../components/global/Gym/GymTable";
import MembersTable from "../../components/global/Member/MemberTable";
import Layout from "../layout";
import "./styles.css";

const CRM = () => {
  return (
    <Layout>
      <div className="content-container">
        <MembersTable />
        <GymTable />
      </div>
    </Layout>
  );
};

export default CRM;
