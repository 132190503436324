import defaultClient from "../client/default";

class CommandSearchService {
  async searchCommand({ query }) {
    const result = await defaultClient
      .get("api/v1/command-search/general-command/", {
        params: { search: query },
        cache: { ttl: 30 * 1000 },
      })
      .catch((_) => {
        throw new Error("Failed to login");
      });

    return result.data?.results || [];
  }
}
export default CommandSearchService;
