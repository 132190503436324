import React from "react";
import icon from "../../../assets/icon.png";
import "./styles.css";

const AppIcon = ({ size = "small" }) => {
  return (
    <div className={`app-icon ${size}`}>
      <img src={icon} alt="App Icon" />
    </div>
  );
};

export default AppIcon;
