import React from "react";
import NewMemberModal from "../../../global/Member/NewMemberModal";
import SearchResultItemBase from "../components/SearchResultItemBase";

const UserSignUpTrigger = ({ command }) => {
  return (
    <NewMemberModal
      key={command.id}
      queryKey={["members-table"]}
      triggerComponent={<SearchResultItemBase result={command} />}
    />
  );
};

export default UserSignUpTrigger;
