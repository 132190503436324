import React, { useState, useEffect } from "react";
import { Avatar, Flex, Text, Select, Spinner } from "@radix-ui/themes";
import UserService from "../../../api/authService/userService";
import { useQuery } from "@tanstack/react-query";

const GymSelectionField = () => {
  const [selectedGym, setSelectedGym] = useState(null);
  const [selectedGymId, setSelectedGymId] = useState(() => {
    const storedGymId = localStorage.getItem("selectedGymId");
    return storedGymId ? parseInt(storedGymId, 10) : null;
  });

  const { data: gyms, isPending: isLoading } = useQuery({
    queryKey: ["unique-gyms-list"],
    queryFn: async () => {
      const userService = new UserService();
      const response = await userService.getGyms({ pageSize: 100 });
      return response.data.results;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (selectedGymId !== null) {
      const gym = gyms?.find((gym) => gym.id === selectedGymId);
      setSelectedGym(gym);
      localStorage.setItem("selectedGymId", selectedGymId);
    } else {
      setSelectedGym(null);
      localStorage.removeItem("selectedGymId");
    }
  }, [selectedGymId, gyms]);

  return (
    <Select.Root
      size={"3"}
      value={selectedGymId !== null ? selectedGymId.toString() : ""}
      defaultValue=""
      onValueChange={(value) =>
        setSelectedGymId(value ? parseInt(value) ?? null : null)
      }
    >
      <Select.Trigger variant={"surface"} style={{ width: "100%" }}>
        <Flex as="span" align="center" gap="2">
          <Avatar fallback={selectedGym?.name[0] ?? "A"} size={"1"} />
          <Text>{selectedGym?.name ?? "Todas academias"}</Text>
        </Flex>
      </Select.Trigger>
      <Select.Content position="popper">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {gyms?.map((gym) => (
              <Select.Item key={gym.id} value={gym.id.toString()}>
                <Flex as="span" align="left" gap="2">
                  <Avatar fallback={gym?.name[0] ?? "A"} size={"1"} />
                  <Text>{gym.name}</Text>
                </Flex>
              </Select.Item>
            ))}
            <Select.Group label="Grupos">
              <Select.Label>Grupos</Select.Label>
              <Select.Item key="all" value="__all__">
                <Flex as="span" align="left" gap="2">
                  <Avatar fallback={"A"} size={"1"} />
                  <Text>Todas academias</Text>
                </Flex>
              </Select.Item>
            </Select.Group>
          </>
        )}
      </Select.Content>
    </Select.Root>
  );
};

export default GymSelectionField;
