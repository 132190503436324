import React from "react";
import "./styles.css";
import { Card, Heading } from "@radix-ui/themes";
import { CheckIcon } from "@radix-ui/react-icons";

const ResourceCard = ({ resource }) => {
  return (
    <Card>
      <div className="resource-card-container">
        <div className="resource-card-title">
          <Heading size="5" align="center">
            {resource.title}
          </Heading>
        </div>
        <div className="resource-card-description">
          <Heading size="3" align="center">
            {resource.description}
          </Heading>
        </div>
        {resource.content.map((content, index) => {
          return (
            <div key={index} className="resource-card-content">
              <div className="resource-card-icon">
                <CheckIcon />
              </div>
              {content}
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default ResourceCard;
