import StaffTable from "../../components/global/Staff/StaffTable";
import Layout from "../layout";

const Staffing = () => {
  return (
    <Layout>
      <div className="content-container">
        <StaffTable />
      </div>
    </Layout>
  );
};

export default Staffing;
