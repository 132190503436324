import React from "react";
import "./styles.css";
import Header from "../../components/global/Land/Header";
import PresentationSection from "../../components/global/Land/PresentationSection";
import MetricSection from "../../components/global/Land/MetricSection";
import ExperienceSection from "../../components/global/Land/ExperienceSection";
import FeedbackSection from "../../components/global/Land/FeedbackSection";
import ResourcesSection from "../../components/global/Land/ResourcesSection";
import GetInTouchSection from "../../components/global/Land/GetInTouchSection";

const LandPage = () => {
  return (
    <div className="content">
      <Header />
      <PresentationSection />
      <MetricSection />
      <ExperienceSection />
      <FeedbackSection />
      <ResourcesSection />
      <GetInTouchSection />
      <div style={{ height: "500px" }} />
    </div>
  );
};

export default LandPage;
