import { Outlet } from "react-router-dom";
import Sidebar from "../components/shared/Sidebar";

const Layout = ({ children }) => {

    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <div style={{ flex: 1 }}>
                { children }
            </div>
        </div>
    )
}

export default Layout;