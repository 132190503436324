import React from "react";
import { Button } from "@radix-ui/themes";

const SidebarItem = ({ icon: Icon, label, active, onClick }) => {
  return (
    <Button
      style={{
        width: "100%",
        cursor: "pointer",
        textAlign: "left",
        justifyContent: "flex-start",
        color: active ? "primary" : "initial",
        backgroundColor: active ? "lightPrimary" : "initial",
      }}
      variant="soft"
      onClick={onClick}
    >
      {Icon && <Icon />}
      {label}
    </Button>
  );
};

export default SidebarItem;
