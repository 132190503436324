import React from "react";
import getTriggerComponent from "../../modules/triggerHandler";
import SearchResultItemBase from "../SearchResultItemBase";

const SearchResultItem = ({ result }) => {
  switch (result.command_type) {
    case "REDIRECT":
      return <SearchResultItemBase result={result} />;
    case "TRIGGER":
      return getTriggerComponent({ command: result });
    default:
      return <SearchResultItemBase result={result} />;
  }
};

export default SearchResultItem;
