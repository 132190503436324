import Cookies from "js-cookie";
import defaultClient from "../client/default";

class AuthService {
  async register(username, password) {
    // TODO: Implement the register method
  }

  async login(username, password) {
    await defaultClient
      .post("api/auth/staff/signin/", {
        username: username,
        password: password,
      })
      .then((response) => {
        // Store the authToken in a cookie
        const expiresAt = new Date(
          new Date().getTime() + response.data.expires_in * 1000
        );
        Cookies.set("authTokenExpiresAt", expiresAt);
        Cookies.set("staffId", response.data.staff_id);
        Cookies.set("authToken", response.data.token);
      })
      .catch((_) => {
        Cookies.remove("staffId");
        Cookies.remove("authToken");
        Cookies.remove("authTokenExpiresAt");
        throw new Error("Failed to login");
      });
  }

  async setPassword(uidb64, token, password) {
    await defaultClient
      .post("api/auth/staff/set-password/", {
        uidb64: uidb64,
        token: token,
        password: password,
      })
      .then((_) => (window.location.href = "/login"))
      .catch((_) => {
        throw new Error("Failed to set password");
      });
  }

  async logout() {
    // TODO: Implement the register method
  }
}
export default AuthService;
