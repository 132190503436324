import React, { useState } from "react";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Card, Flex, Text, TextField } from "@radix-ui/themes";
import AuthService from "../../api/authService/authService";

const SetPasswordPage = () => {
  const navigate = useNavigate();
  const authService = new AuthService();
  const { uidb64, token } = useParams();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Senhas não coincidem");
      return;
    }
    setLoading(true);
    await authService
      .setPassword(uidb64, token, password)
      .then((_) => {
        toast.success("Password set successfully");
        navigate("/login");
      })
      .catch((error) => {
        toast.error("Failed to set password");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Flex
        maxWidth="500px"
        direction="column"
        style={{ gap: "1rem", width: "100%" }}
      >
        <Card
          variant="surface"
          style={{ maxWidth: "400px", margin: "10px", padding: "20px" }}
        >
          <Text as="h2">Defina a sua senha</Text>
          <form
            onSubmit={handleSubmit}
            style={{
              gap: "1rem",
              display: "flex",
              padding: "1rem",
              flexDirection: "column",
            }}
          >
            <TextField.Root
              required
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField.Root
              required
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button type="submit" disabled={loading}>
              {loading ? "Definindo senha..." : "Definir senha"}
            </Button>
          </form>
        </Card>
      </Flex>
    </div>
  );
};

export default SetPasswordPage;
