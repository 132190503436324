import StaffSignUpTrigger from "../triggers/staffSignUpTrigger";
import UserSignUpTrigger from "../triggers/userSignUpTrigger";

const TRIGGER_COMPONENTS = {
  "user-sign-up": UserSignUpTrigger,
  "staff-sign-up": StaffSignUpTrigger,
};

const getTriggerComponent = ({ command }) => {
  const TriggerComponent = TRIGGER_COMPONENTS[command.trigger_code];
  if (!TriggerComponent) {
    throw new Error(`Unknown trigger code: ${command.trigger_code}`);
  }
  return <TriggerComponent command={command} />;
};

export default getTriggerComponent;
