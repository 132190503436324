import React from "react";
import {
  Card,
  Avatar,
  IconButton,
  Badge,
  Text,
  Button,
  DataList,
  Tabs,
  Box,
} from "@radix-ui/themes";
import { FaEnvelope, FaPhone, FaWhatsapp } from "react-icons/fa";
import "./styles.css";
import MemberAccessDetails from "./components/MemberAccountDetails";

const MemberDialogView = ({ member, onClose }) => {
  return (
    <Card variant="surface">
      <div className="header-container">
        <Avatar size="9" src={member.avatar} fallback="A" />
        <div className="header-column">
          <div className="name-container">
            <Text>{member.name}</Text>
            <Badge size={"2"}>{member.member_type}</Badge>
          </div>
          <Text size={"1"}>{member.email}</Text>
        </div>
      </div>
      <div className="tags-container"></div>

      <Tabs.Root defaultValue="account">
        <Tabs.List>
          <Tabs.Trigger value="account">Detalhes da conta</Tabs.Trigger>
          <Tabs.Trigger value="documents">Financeiro</Tabs.Trigger>
          <Tabs.Trigger value="settings">Acesso</Tabs.Trigger>
        </Tabs.List>

        <Box pt="3">
          <Tabs.Content value="account">
            <MemberAccessDetails member={member} />
          </Tabs.Content>

          <Tabs.Content value="documents">
            <Text size="2">Access and update your documents.</Text>
          </Tabs.Content>

          <Tabs.Content value="settings">
            <Text size="2">
              Edit your profile or update contact information.
            </Text>
          </Tabs.Content>
        </Box>
      </Tabs.Root>
    </Card>
  );
};

export default MemberDialogView;
