import { Button, Card, Flex, Heading, TextField } from "@radix-ui/themes";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../api/authService/authService";
import { toast } from "react-toastify";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();
    const authService = new AuthService();
    await authService
      .login(username, password)
      .then(() => navigate("/dashboard"))
      .catch((error) => toast.error("Usuário ou senha incorretos"))
      .finally(() => setLoading(false));
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Flex
        maxWidth="400px"
        direction="column"
        style={{ gap: "1rem", width: "100%" }}
      >
        <Card variant="surface">
          <form
            onSubmit={handleLogin}
            style={{
              gap: "1rem",
              display: "flex",
              padding: "1rem",
              flexDirection: "column",
            }}
          >
            <Heading>Bem vindo ao Olympia Fit AI!</Heading>
            <TextField.Root
              placeholder="Email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />

            <TextField.Root
              type="password"
              value={password}
              placeholder="Senha"
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button type="submit" loading={loading}>
              Login
            </Button>
          </form>
        </Card>
      </Flex>
    </div>
  );
};

export default Login;
