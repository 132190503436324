import React from "react";
import "./styles.css";
import { LinkedInLogoIcon } from "@radix-ui/react-icons";
import { Avatar, Card, Separator, Text } from "@radix-ui/themes";

const ExperienceCard = ({ experience }) => {
  return (
    <Card className="experience-card">
      <div className="experience-card-header">
        <div className="experience-card-header-title">
          <Avatar src={experience.avatar} />
          <div className="experience-card-column">
            <Text>{experience.name}</Text>
            <Text size={"1"}>{experience.profession}</Text>
          </div>
        </div>
        <LinkedInLogoIcon />
      </div>
      <Separator
        orientation={"horizontal"}
        style={{ margin: "10px 0 20px 0" }}
      />
      <Text>{experience.feedback}</Text>
    </Card>
  );
};

export default ExperienceCard;
