import React from "react";
import "./styles.css";
import { Heading } from "@radix-ui/themes";
import ExperienceCard from "../ExperienceCard";

const feedbacks = [
  {
    name: "Anna Smith",
    profession: "Especialista em gerente de academia",
    feedback:
      "O GymAl revolucionou meu fluxo de trabalho, economizando tempo e aumentando drasticamente a eficiencia.",
    avatar: "https://randomuser.me/api/portraits/women/13.jpg",
  },
  {
    name: "John Doe",
    profession: "Personal Trainer",
    feedback:
      "O GymAl me ajudou a me comunicar melhor com meus clientes e a manter um historico de treino mais detalhado.",
    avatar: "https://randomuser.me/api/portraits/men/3.jpg",
  },
  {
    name: "Jane Doe",
    profession: "Cliente",
    feedback:
      "O GymAl me ajudou a manter o foco em meus objetivos e a me manter motivado.",
    avatar: "https://randomuser.me/api/portraits/women/68.jpg",
  },
  {
    name: "Steve Smith",
    profession: "Gerente de academia",
    feedback:
      "O GymAl me ajudou a manter o controle de todos os aspectos da minha academia em um so lugar.",
    avatar: "https://randomuser.me/api/portraits/men/60.jpg",
  },
  {
    name: "Maria Silva",
    profession: "Cliente",
    feedback:
      "O GymAl me ajudou a manter o foco em meus objetivos e a me manter motivado.",
    avatar: "https://randomuser.me/api/portraits/women/33.jpg",
  },
  {
    name: "Joao Silva",
    profession: "Cliente",
    feedback:
      "O GymAl me ajudou a manter o foco em meus objetivos e a me manter motivado.",
    avatar: "https://randomuser.me/api/portraits/men/5.jpg",
  },
];

const FeedbackSection = () => {
  return (
    <div className="feedback-container">
      <Heading size="8">Ouca o que nossos clientes dizem</Heading>
      <div className="feedback-items-container">
        <ExperienceCard experience={feedbacks[0]} />
        <ExperienceCard experience={feedbacks[1]} />
        <ExperienceCard experience={feedbacks[2]} />
        <ExperienceCard experience={feedbacks[3]} />
        <ExperienceCard experience={feedbacks[4]} />
        <ExperienceCard experience={feedbacks[5]} />
      </div>
    </div>
  );
};

export default FeedbackSection;
