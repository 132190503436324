import React from "react";
import { Badge, Skeleton, Table, Text } from "@radix-ui/themes";

const LoadingRow = () => {
  return (
    <Table.Row>
      <Table.Cell>
        <Skeleton>
          <Text>User Name Structure</Text>
        </Skeleton>
      </Table.Cell>
      <Table.Cell>
        <Skeleton>
          <Badge size={"2"}>EXAMPLE</Badge>
        </Skeleton>
      </Table.Cell>
      <Table.Cell>
        <Skeleton>
          <Text>XX/XX/XXXX</Text>
        </Skeleton>
      </Table.Cell>
      <Table.Cell>
        <Skeleton>
          <Text>XX/XX/XXXX</Text>
        </Skeleton>
      </Table.Cell>
    </Table.Row>
  );
};

export default LoadingRow;
